@import "vars_aurelio";

.dropdown-menu {
  .notification-badge {
    background-color: $main-color;
  }
}
#landing-page .dropdown-menu {
  .notification-badge, #landing-page .notification-badge.notification-dot {
    background-color: $main-color;
  }
}

.notifications-button {
  i {
    position: relative;
    z-index: 200;
  }
  span.badge {
    z-index: 100;
  }
}

.notification-badge {
  &.notification-dot {
    &:empty {
      display: block;
    }

    background-color: $main-color;
    border-radius: 5px;

    position: relative;
    display: block;
    height: 10px;
    width: 10px;
    padding: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    float: right;
  }
}

.navbar-nav .user-menu-dropdown.dropdown-toggle {
  &[aria-expanded=true] {
    ~ .notification-badge, ~ .notification-dot {
      display: none;
    }
  }

  i {
    margin-right: 10px;
  }

  ~ .notification-dot {
    position: absolute;
    top: 5px;
    left: 25px;
    right: unset;
  }
}

/* DEBUG STYLING */
pre.debug {
    border: 1px solid #AAAAAA;
    color: #333333;
    background-color: #EEEEEE;
    padding: .5em 1em;
    margin: 1em .5em;
    min-height: 29px;

    font-family: 'Courier New', Courier, monospace;

    overflow: auto;
    position: relative;
    white-space: nowrap;

    &:before {
        content: "debug";
        position: absolute;
        right: 0;
        top: 0;

        border-radius: 0;
        border: 1px solid #AAAAAA;

        background-color: #AAAAAA;
        color: #EEEEEE;
        padding: .25em .5em;
    }

    ul, li {
        list-style: none inside;
    }

    br { display: none; }

    ul {
        padding-left: 0;

        li {
            padding-left: 1em;

            a {
                font-weight: bold;
                color: #56B9F1;
            }
        }
    }    
}

.production pre.debug {
    position: fixed;
    top: 10px;
    right: 10px;
    min-width: 4.3em;
    max-height: calc(100vh - 20px - 28px);
    max-width: calc(100vw - 20px - 28px);
    z-index: 0;

    ul {
        display: none;
    }

    &:hover {
        z-index: 999999;
        
        ul {
            display: block;
        }
    }
}